@font-face {
  font-family:ITCAvantGardeStd-Bold;
  src: url('../public/Images/ITCAvantGardeStd-Bold.ttf');
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family:ITCAvantGardeStd-Bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
