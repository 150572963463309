.landing__page {
  min-height: 100vh;
  background-image: url(../public/Images/loading_imagebg.png);
  background-size: cover;
  background-position: center;
}

.mint > .row > div {
  min-height: 100vh;
}

.hero_img {
  cursor: pointer;
}
.hero_blocked {
  cursor: not-allowed;
}

/* Black Box */
.mint__left {
  background-color: #000;
  min-height: 100vh;
  position: relative;
}

/* Black Box Text */
.big__texts > h3 {
  font-size: 35px;
  line-height: 33px;
}

.big__texts > h1 {
  font-size: 55px;
  line-height: 51px;
}

/* Gray Box in Black Box */
.gray__box > img {
  height: 150px;
  transition: 0.15s;
}
.gray__box > img:hover {
  opacity: 0.8;
}

/* Mint Right */
.mint__right {
  position: relative;
}

.mint__right > footer > div > h6 {
  font-size: 10px;
}

.mint__right > footer {
  position: absolute;
  bottom: 0;
}

.mint__right > h5 {
  cursor: pointer;
}

/* Red Box */
.mint__right > img {
  height: 250px;
  cursor: pointer;
  transition: 1s;
}
.mint__right > img:hover {
  -webkit-animation: flicker 0.25s linear infinite;
  animation: flicker 0.25s linear infinite;
  transition: 1s;
}

/* Corner Texts */
.text__small > h4 {
  cursor: pointer;
  transition: 0.25s;
}
.text__small > h4:hover {
  color: rgb(196, 56, 56);
}
.text__small > h6 {
  font-size: 12px;
}

.mint__right > h6 {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 20px;
  font-size: 10px;
}

.mint__left__top > h6 {
  font-size: 10px;
}

.mint__left > .footer > div > h6 {
  font-size: 10px;
}

/* Input Buttons */
.noButtons {
  display: none;
}

.mintButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Mint Button */
.mint__button {
  width: 250px;
  height: 50px;
  padding: 5px 0px;
  outline: none;
  border: 3px solid rgb(0, 0, 0);
  margin: 5px;
  text-align: center;
  border-radius: 5px;
  line-height: 38px;
  cursor: pointer;
  transition: 0.35s;
}

.mint__button:hover {
  background-color: #000;
  color: #fff;
}

/* Social Icons */
.s__icons {
  position: absolute;
  top: 0;
}

.s_icon > img {
  cursor: pointer;
  height: 20px;
  margin: 5px 10px;
}
.s_icon > img:hover {
  opacity: 0.7;
}

/* Link colors */
a {
  text-decoration: none;
  color: #000000;
}
a:hover {
  color: #b54b50;
}

@media screen and (max-width: 900px) {
  .mint__left__top > div > img {
    height: 120px;
  }
}

@media screen and (max-width: 750px) {
  .mint__left > h3 {
    font-size: 24px;
    line-height: 24px;
  }

  .mint__left > h1 {
    font-size: 45px;
    line-height: 44px;
  }
}

@keyframes flicker {
  50% {
    transition: 1s;
    transform: translateY(10px);
  }
}
